const kz = {
  action: {
    participate: 'Қатысу',
    disable: 'Лотереяны өшіру',
    support: 'Толық ақпарат алу үшін немесе туындаған мәселені шешу үшін \n' +
      '<a href="tel:8-800-080-9351">8-800-080-9351</a> тегін нөміріне қоңырау шалыңыз'
  },
  menu: {
    logo: 'Ұлттық<br />лотерея',
    participate: 'Лотереяға қатысу',
    prizes: 'Жүлделер',
    rules: 'Лотерея ережелері',
    feedback: 'Кері байланыс',
    disable: 'Лотереяны өшіру',
    localeKz: 'kz',
    localeRu: 'ru',
    menu: 'МӘЗІР'
  },
  common: {
    titleApp: 'Ұлттық лотерея'
  },
  banner: {
    header: '«Сәттілік» лотереяысында миллион ұтып алыңыз',
    label: 'Beeline абоненттері үшін ақшалай ұтысы бар лездік лотерея!',
    desc: 'Жазылымды рәсімдеп, күн сайын жаңа билет алыңыз. ',
    appeal: 'Билеттер неғұрлым көп болса, миллион ұтып алу мүмкіндігі соғұрлым жоғары болады!'
  },
  promo: {
    header: 'Күн сайын қатысыңыз',
    desc: '«Сәттілік» лотереясына жазылыңыз және күн сайын SMS арқылы бір лотерея билетін алыңыз. ',
    appeal: 'Билеттер неғұрлым көп болса, миллион ұтып алу мүмкіндігі соғұрлым жоғары болады!'
  },
  prizes: {
    header: 'Жүлделер',
    label: '«Сәттілік» лотереяысының жүлде қоры:',
    prize0: '100 ₸ 1 324 088 жүлде',
    prize1: '200 ₸ 1 269 280 жүлде',
    prize2: '400 ₸ 222 538 жүлде',
    prize3: '1 000 ₸ 31 960 жүлде',
    prize4: '2 000 ₸ 4 176 жүлде',
    prize5: '4 000 ₸ 2 088 жүлде',
    prize6: '10 000 ₸ 1 080 жүлде',
    prize7: '18 000 ₸ 132 жүлде',
    superPrizeLabel: '3 супер-жүлде',
    superPrize: '1 000 000 ₸',
    buttonSubscribe: 'Миллион ұтып алғым келеді!',
    buttonUnsubscribe: 'Лотереяны өшіру'
  },
  cards: {
    desc1: '«Сәттілік» лотереясы Қазақстан Республикасының «Сәтті Жұлдыз» \n' +
      'АҚ ұлттық лотереясымен әзірлеген.',
    desc2: 'Толық ақпарат алу үшін немесе туындаған мәселені шешу үшін \n' +
      '<a href="tel:8-800-080-9351">8-800-080-9351</a> тегін нөміріне қоңырау шалыңыз'
  },
  rules: {
    header: 'Лотерея ережелері',
    rule0: '«Сәттілік» лотереясына жазылымды қосыңыз және күн сайын 1 лотерея билетін алып тұрыңыз.',
    rule1: 'Билет бірден ойнатылады: Сіз ұттыңыз ба, жоқ па, бірден білесіз.',
    rule2: 'Егер Сіздің билетіңіз ұтса, ақшалай жүлде Сіздің абоненттік шотыңызға бірден аударылады.. ' +
      'Егер Сіз супер-жүлдені ұтып алсаңыз, ұтысты тапсыру үшін Сізбен хабарласамыз.',
    rule3: 'Сіздің билетіңіз ұтпады ма? Қапа болмаңыз! Сіз қосымша лотерея билетін сатып алып, бағыңызды қайта сынай аласыз.',
    rule4: 'Миллион ұтып алу мүмкіндігіңізді арттыру үшін күн сайын лотерея ойнаңыз!',
    button: 'Лотереяның барлық ережелері'
  },
  winners: {
    title: 'Лотерея жеңімпаздары',
    winner0: {
      name: 'Анвар Т.',
      city: 'Алматы',
      prize: '1 000 000 ₸'
    },
    winner1: {
      name: 'Нурлан С.',
      city: 'Алматы',
      prize: '1 000 000 ₸'
    },
    winner2: {
      name: 'Анвар Т.',
      city: 'Алматы',
      prize: '1 000 000 ₸'
    }
  },
  modal: {
    subscribe: {
      title: 'Лотереяға қатысыңыз',
      desc: 'Күн сайын SMS арқылы лотерея билеттерін алыңыз және миллион ұтуға тырысып көріңіз!<br /><br />' +
        'Beeline абоненттері үшін «Сәттілік» лотереясына қатысу үшін сізде жазылым болуы керек, құны 100 ₸/күн<br /><br />' +
        '<b>Жазылымды қосу</b> үшін ұялы телефонда <a href="tel:*88%23">*88#</a> USSD теріңіз<br /><br />' +
        '<b>Тағы бір немесе бірнеше билет сатып</b> алу үшін, *88*N# командасын теріңіз, мұндағы N - 1-ден 10-ға дейінгі билеттер саны. Мысалы, 10 билет сатып алу үшін, <a href="tel:*88*10%23">*88*10#</a> командасын теріңіз.' +
        ' Сатып алынған әр билеттің құны 100 ₸<br /><br />' +
        'Нақты ақпарат алу немесе туындаған мәселені шешу үшін <a href="tel:8-800-080-9351">8-800-080-9351</a> тегін нөміріне соғыңыз',
      button: 'Жарайды!'
    },
    unsubscribe: {
      title: 'Лотереяға қатысыңыз',
      desc: 'Лотереяға қатыса отырып, Сіз күнделікті билеттермен бірге миллион теңге ұтып алуға мүмкіндік аласыз!<br /><br />' +
        'Лотереяға жазылуды өшіру үшін <a href="tel:*88*0%23">*88*0#</a> теріңіз немесе <a href="tel:8-800-080-9351">8-800-080-9351</a> қызу желісіне соғыңыз.',
      button: 'Жарайды!'
    }
  },
  card: {
    desc: '«Сәттілік» лотереясы Қазақстан Республикасының «Сәтті Жұлдыз» АҚ ұлттық лотереясымен әзірлеген.',
    feedback: 'Толық ақпарат алу үшін немесе туындаған мәселені шешу үшін \n' +
      '<a href="tel:8-800-080-9351">8&#8209;800&#8209;080&#8209;9351</a> тегін нөміріне қоңырау шалыңыз.'
  },
  footer: {
    copyright: `${new Date().getFullYear()} © «Сәтті Жұлдыз» АҚ`,
    feedback: 'Кері байланыс',
    terms: 'Пайдалану шарттары'
  }
};

export default kz;
